﻿h1 {
    font-family: 'Aktiv Brotesk', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

h2 {
    font-family: 'Aktiv Brotesk', sans-serif;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 700;
}

h3 {
    font-family: 'Aktiv Brotesk', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

h4 {
    font-family: 'Aktiv Brotesk', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

h5 {
    font-family: 'Aktiv Brotesk', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
p, input {
    font-family: 'Aktiv Brotesk', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

label {
    font-family: 'Aktiv Brotesk', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
}

input::placeholder {
    font-family: 'Aktiv Brotesk', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
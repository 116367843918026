﻿.alice-footer {
    flex: 0 1 auto;
    position: relative;
    width: 100vw;
    background-color: $purple;
    height: 60px;
    margin: 0px;
    text-align: center;
    display: inline-block;

    @media screen and (max-width: 769px) {
        padding-bottom: 140px;
    }

    .uhm-logo {
        margin-left: calc(50% - 118px);
        float: left;
        padding-top: 20px;
    }

    p {
        margin-left: 20px;
        float: left;
        padding-top: 20px;
        font-size: 12px;
        color: #fff;
    }
}

﻿.question-list-container {
    width: 100vw;
    padding: 0px;
    flex: 1 1 auto;
    width: 100vw;
    margin-top: 55px;

    @media only screen and (max-width: 769px) {
        margin: 0px;
    }

    .col.half.questions-list {
        text-align: left;
        width: 31.25%;
        max-width: 1400px;

        @media only screen and (max-width: 769px) {
            padding: 15px 0px;
            width: 90%;
            margin-right: 0px;
            margin-left: 5%;
        }

        h2 {
            font-size: 20px;
            font-weight: 700;
            color: #3D1951;
        }

        ul {
            padding: 0px;
        }

        li {
            display: flex;
            justify-content: space-between;
            border-width: 0px 0px 1px 0px !important;
            border-style: solid;
            border-color: black;
            padding-left: 0px;
            font-size: 16px;
            font-weight: 400;
            color: #242E40;
            padding-top: 12px;
            padding-bottom: 12px;
            align-items: center;

            svg {
                min-width: 24px;
            }
        }

        li:after {
            display: none;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .left-questions {
        margin-left: 15%;
        margin-right: 3.75%;
        margin-bottom: 50px;

        @media only screen and (max-width: 769px) {
            margin-top: 50px;
        }
    }

    .right-questions {
        margin-left: 3.75%;
        margin-right: 15%;
        margin-bottom: 50px;
    }
}

.question-list-container-hidden {
    display: none;

    .left-questions {
        height: 0;
    }

    .right-questions {
        height: 0;
    }
}

.delete-icon {
    height: 16px;
    color: #918B96;
}

.delete-icon:hover {
    color: $purple;
    cursor: pointer;
}

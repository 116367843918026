﻿.alice-feedback-answer-page {
    width: 100vw;
    top: 70px;
    background-color: #F6F3F7;

    #answer {
        h1 {
            margin-left: 5px;
            font-size: 24px;
        }

        @media screen and (max-width: 426px) {
            max-width: 95vw;
        }
    }

    .answer {
        text-align: left;
        margin-top: 20px;

        .answer-content {
            p, li {
                font-size: 16px;
            }

            ol {
                display: block;
                list-style-type: decimal;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;
            }

            ol ol {
                margin-block-start: 0px;
                margin-block-end: 0px;
            }

            ul {
                display: block;
                list-style-type: disc;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;
            }

            ul ul {
                list-style-type: circle;
                margin-block-start: 0px;
                margin-block-end: 0px;
            }

            ul li {
                list-style-type: disc;
                padding: 0px 0px 0px 0px;
            }

            ul li:after {
                width: 0px;
                height: 0px;
            }
            /* Display the dropdown inline with the last line of the answer (only if it is text) */
            & > p:nth-last-child(2) {
                display: inline;
            }

            .decision-list {
                li {
                    list-style-type: none
                }
            }

        }
    }

    .plus-icon {
        width: 16px;
        margin-bottom: -7px;
    }

    .button-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        margin: 0 auto;
        padding: 15px;
        max-width: 98vw;

        @media screen and (min-width: 1024px) {
            max-width: 1200px;
            padding: 15px 40px;
        }

        @media screen and (max-width: 426px) {
            padding: 15px 0;
            margin: 0 0;
        }
    }

    .save {
        border-radius: 25px;
        color: $green;
        background-color: #F6F3F7;
        border: 1px solid #F6F3F7;
        padding: 10px 10px;
        font-size: 12px !important;
        letter-spacing: 1px;
        margin: 0;
        width: 260px;

        @media screen and (max-width: 426px) {
            width: 150px;
        }


        &:focus, &:active, &:hover {
            padding: 10px 10px !important;
            border-radius: 25px;
            color: $white;
            background-color: $green;
            margin: 0;
        }

        #save-button-text {
            line-height: 20px;

            .this-question-text {
                @media screen and (max-width: 426px) {
                    display: none;
                }
            }
        }

        .save:hover {
            border-color: $green;
        }
    }
}

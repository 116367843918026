﻿#root {
    overflow-x: hidden;
}

#aliceContentGrid {
    background-color: #F6F3F7;
    height: 100vh;
    min-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    .content-flexbox {
        display: flex;
        flex-flow: column;
        min-height: 100vh;
    }

    .alice-hero {
        flex: 0 1 auto;
        width: 100vw;
        margin: 0px;
        padding: 0px;
        height: 305px;

        @media only screen and (max-width: 769px) {
            height: 128px;
        }

        .alice-banner-img {
            width: 100vw;
            height: 305px;
            background: center / cover no-repeat;
            -moz-box-shadow: inset 0px 20px 50px #301245;
            -webkit-box-shadow: inset 0px 20px 50px #301245;
            box-shadow: inset 0px 20px 50px #301245;

            @media only screen and (max-width: 769px) {
                height: 128px;
                background: center / cover no-repeat;
                -moz-box-shadow: inset 0px 15px 20px #301245;
                -webkit-box-shadow: inset 0px 15px 20px #301245;
                box-shadow: inset 0px 15px 20px #301245;
            }
        }

        .alice-banner-video {
            width: 100vw;
            height: 305px;
            object-fit: fill;
            background: center / cover no-repeat;
            -moz-box-shadow: inset 0px 20px 50px #301245;
            -webkit-box-shadow: inset 0px 20px 50px #301245;
            box-shadow: inset 0px 20px 50px #301245;

            @media only screen and (max-width: 769px) {
                height: 128px;
                object-fit: fill;
                background: center / cover no-repeat;
                -moz-box-shadow: inset 0px 15px 20px #301245;
                -webkit-box-shadow: inset 0px 15px 20px #301245;
                box-shadow: inset 0px 15px 20px #301245;
            }
        }

        .edit-banner {
            font-size: 25px;
            background-color: $transparent-white;
            color: $purple;
            border: 1px solid $white;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            float: right;
            position: relative;
            bottom: 70px;
            right: 40px;

            @media only screen and (max-width: 769px) {
                font-size: 20px;
                width: 40px;
                height: 40px;
                position: relative;
                bottom: 115px;
                right: 20px;
            }
        }

        .edit-banner:hover {
            background-color: $light-gray;
            cursor: pointer;
        }
    }

    .alice-help {
        flex: 0 1 auto;
        width: 100vw;
        margin: 0px;
        padding: 0px;

        .help-section {
            top: -100px;
            background: transparent;

            @media only screen and (max-width: 769px) {
                top: 0px;
            }

            .wrap {
                margin-top: 0px;

                h2 {
                    max-width: 816px;
                    margin: auto;
                }

                ul {
                    max-width: 816px;
                    margin: auto;
                }

                li:after {
                    top: 0.2em;
                    left: 0.1em;
                    content: "\279C";
                    background-color: transparent;
                }

                #help-wrap {
                    padding: 0px;
                }
            }
        }
    }
}

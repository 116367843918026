﻿/* UHM Color Palette */
$purple: rgba(61, 25, 81, 1);
$trans-purple: rgba(61, 25, 81, 0.5);
$green: rgba(46, 131, 55, 1);
$trans-green: rgba(46, 131, 55, 0.5);
$light-gray: rgba(247, 247, 247, 1);
$dark-gray: rgba(23, 23, 23, 1);
$border-gray: rgba(206, 206, 206, 1);
$black: rgba(0, 0, 0, 1);
$white: rgba(255, 255, 255, 1);
$transparent-white: rgba(255, 255, 255, 0.5);
$error: rgba(208, 0, 0, 1);

/* Typography */
$sans-serif: 'Raleway', sans-serif;
$serif: 'Merriweather', serif;


/* Shapes */
$circle-border-radius: 50%;

/* Screen Sizes */
$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;
$larger: 1200px;
$largest: 1600px;
$laptop: 1800px;

/* Padding */
$content-padding-small: 50px 0px;
$content-padding-large: 75px 0px;


// center vertically and/or horizontally an absolute positioned element

@mixin center($xy: xy) {
    @if $xy == xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        @include transform(translateX(-50%) translateY(-50%));
    }
    @else if $xy == x {
        left: 50%;
        right: auto;
        @include transform(translateX(-50%));
    }
    @else if $xy == y {
        top: 50%;
        bottom: auto;
        @include transform(translateY(-50%));
    }
}


@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}
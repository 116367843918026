﻿a.button.choice,
a.button.choice,
a.button.choice:active {
    text-transform: none;
    padding: 10px;
    font-size: 18px;
    line-height: 1.5;
    margin: 5px auto;
    width: auto;
    display: block;
    color: $purple;
    border: 0px solid $purple;
    background-color: #efefef;

    &:hover,
    &:focus {
        background-color: $purple;
        color: $white;
    }
}

.content .wrap {
    @media screen and (min-width: $desktop) {
        padding: 0 50px;
    }
}

.hero.access-denied {
    background-image: url("/scss/assets/rabbit-hole.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;

    .wrap {
        margin-top: 75px;
    }
}

section.content {
    text-align: left;
}

.invisible {
    display: none;
}

.share {
    background-color: $light-gray;
}

[type="button"]:hover:hover:disabled:after,
[type="reset"]:hover:hover:disabled:after,
[type="submit"]:hover:hover:disabled:after,
button:hover:hover:disabled:after {
    display: none;
}

h1 {
    font-size: 25px;
    line-height: 32px;
    /*@media screen and (min-width: $tablet) {
        font-size: 35px;
        line-height: 40px;
    }*/
}

p,
ul li,
ol li {
    font-size: 20px;
    line-height: 1.5;
}

ul li:after {
    top: 0.8em;
}

.wrap.extra-large {
    max-width: 1920px;

    .logo-uhm-holder {
        @media screen and (min-width: $tablet) {
            max-width: 200px;
        }
    }
}

.hero {
    height: auto;
    max-height: none;

    .wrap {
        margin-top: 75px;

        @media screen and (min-width: $tablet) {
            margin-top: 100px;
        }
    }
}

.answer-section {
    min-height: 400px;
}

.loader {
    z-index: 1;
}

.logo {
    height: 100%;

    h3 {
        height: auto;
        margin: 15px 0px;
    }

    &.show-logo {
        height: auto;
    }

    img {
        margin: 0 auto;
        border-radius: 10px;
    }

    &.hide-logo {
        transform: scale(0);
        height: 100px;

        h3 {
            opacity: 0;
            height: 0;
        }
    }
}

.logo-video {
    max-width: 816px;
    width: 100%;
    border: 4px solid $purple;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 35px;
}

.banner-video {
    max-width: 1150px;
    max-height: 290px;
    width: 100%;
    border: 2px solid $purple;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 35px;
}

.answer-header {
    margin-bottom: 50px;
}

.decision-header {
    margin-bottom: 50px;
}

.decision-select {
    color: $green;

    span {
        text-decoration: underline;
    }

    .chevron-down {
        vertical-align: middle;
        width: 1em;
    }

    &:hover {
        font-weight: normal;
        text-decoration: none;
    }
}

.decision-choices {
    margin: 0px 20px;
    position: relative;
    min-width: max-content; 

    @media screen and (max-width: $mobile) {
        margin: 0px;
    }
}

.decision-list {
    display: inline-flex; 

    .decision-list-arrow {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        width: max-content;
        height: max-content;
        position: relative;
        left: 50%;

        .decision-arrow-outline {
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            border-bottom: 11px solid $black;
            position: absolute;
        }

        .decision-arrow {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $white;
            position: absolute;
        }
    }

    li::after {
        display: none;
    }

    @media screen and (max-width: $mobile) {
        display: block;

        .decision-list-arrow {
            position: relative;
            left: 15%
        }
    }
}


.choices {
    max-width: 400px;
    min-width: 200px;
    width: max-content;
    height: max-content;
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -92px;
    z-index: 5;
    
    ul {
        padding: 0px;
        text-align: center;

        li {
            border-bottom: 1px solid grey;
            padding: 0px;
            font-size: 16px;

            a {
                display: block;
                text-decoration: none;
                border-radius: 5px;
                margin: 3px;
                padding: 5px;

                &:hover {
                    background-color: $green;
                    color: $white;
                    font-weight: normal;
                }
            }
        }

        li:last-child {
            border-bottom: none;
        }

        li:after {
            content: none;
        }
    }

    ul:after {
        background-color: red;
        width: 15px;
        height: 15px;
    }

    @media screen and (max-width: $mobile) {
        left: 0px;
    }
}

.answer {
    font-size: 20px;
    line-height: 1.5;
    text-align: center;

    img {
        width: auto !important;
        text-align: center;
        margin: 0 auto;
        display: block;
    }

    @media screen and (min-width: $desktop) and (max-width: $larger) {
        margin: 25px 0;
    }

    @media screen and (min-width: larger) {
        margin: 10px 0;
    }
}

ul {
    padding: unset;
}

table,
table tr {
    &:hover {
        background-color: #fff;
    }

    td {
        ol {
            margin: 0px 20px;
            padding: 0 0 0 5px;

            li {
                padding: 5px 0 5px 5px;
            }
        }
    }
}

table th, table td {
    border: 1px solid #666;
}

table th {
    font-size: 16px !important;
    color: $dark-gray;
    font-variant: normal;
    text-transform: none;
    background: transparent;
}

.decision {
    margin: 5px;
}

.hidden-section {
    @media screen and (min-width: $tablet) {
        padding: 0;
        margin: 0;
    }
}

.error {
    h1 {
        text-align: center;
        color: $error;
    }
    
    details > summary {
        text-align: center;
        cursor: pointer;
    }
}

﻿/* Feedback Component */

html {
    background: #F6F3F7;
}

.alice-feedback {
    flex: 0 1 auto;
    width: 100vw;
}

.alice-feedback-answer-page {
    flex: 1 1 100%;
    display: flex;
    flex-flow: column;
    height: 100%;

    .alice-answer {
        flex: 1 1 auto;
    }

    .feedbackElement {
        flex: 0 1 auto;
    }
}

.feedback-section {
    padding: 25px 0px 25px 0px;
    /*position: relative;*/
    top: 0px;
    background-color: #EDE9F0;
    border-top: 1px solid #C5BECC;

    h4 {
        color: $purple;

        @media screen and (max-width: 426px) {
            text-align: left;
            margin-left: 5%;
            width: 90%;
        }
    }

    p {
        margin: auto;
        font-size: 14px;
        width: 40%;

        @media screen and (max-width: 769px) {
            width: 70%;
        }

        @media screen and (max-width: 426px) {
            text-align: left;
            margin-left: 5%;
            width: 90%;
        }
    }
}

#feedback-main {
    float: left;
}

.feedback-container {
    width: 100%;
    margin: 20px 0 0 0;

    @media screen and (max-width: $tablet) {
        padding-left: 2.5%;
    }

    @media screen and (max-width: 426px) {
        padding-left: 25%;
    }

    .col.half {
        float: left;
        width: auto;
    }

    .left-feedback-col {
        margin-left: calc(50% - 218px);
    }
}

#feedback-div {
    position: relative;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
}

.feedback-input {
    border: 1px solid #3d1951;
    border-radius: 3px;
    width: 300px;
    background-color: #fff;
    margin: 5px 20px 15px 0;
    padding: 20px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    font-size: 16px;
}

.alternateSelect {
    opacity: 1 !important;
}

#alternateQuestions {
    display: none;
}

#feedback-comment {
    background-size: 30px 30px;
    background-position: 11px 8px;
    background-repeat: no-repeat;
    border-color: $purple;
    width: 100%;
    margin: 5px 0px;
    height: 150px;
    line-height: 150%;
    resize: vertical;
}

#feedbackForm {
    max-width: 500px;
    margin: 20px auto;

    .select, select {
        width: 100%;
        margin: 5px 0px 15px 0;
    }

    .alt-opt {
        margin-left: 10px;
        line-height: 1.5;
    }

    button {
        width: 100%;
    }

    #feedback-button {
        width: 400px;
        height: 40px;
        border-radius: 35px;
        line-height: 0px;
    }
}

input[type="radio"] {
    opacity: 1;
}

.errorMessage {
    font-size: 20px;
    line-height: 1.5;
}


button {

    &.thumbs-up, &.thumbs-down {
        background-color: #fff;
        font-size: 40px;
        width: 192px;
        height: 40px;
        padding: 5px;
        border-radius: 33px;
        margin: 0px;
        display: block;

        @media screen and (max-width: 426px) {
            width: 162px;
        }

        &:active, &:hover, &:focus {
            background-color: $white;
            border-radius: 33px;
            padding: 5px;
            margin: 0;
        }
    }

    &.thumbs-up {
        color: $green;
        cursor: pointer;
        border: 1px solid $green;

        &:active, &:hover, &:focus {
            color: $green;
        }

        &.selected {
            background-color: $green;
            color: $white;

            h5 {
                color: $white;
            }
        }

        h5 {
            margin-top: 5px;
            color: $green;
            font-size: 15px;
            font-weight: bold;
        }
    }

    .thumbs-up-img {
        margin-left: 15px;
        float: left;
    }

    &.thumbs-down {
        color: #b13f3f;
        cursor: pointer;
        border: 1px solid #b13f3f;

        &:active, &:hover, &:focus {
            color: #b13f3f;
        }

        &.selected {
            background-color: #b13f3f;
            color: $white;

            h5 {
                color: $white;
            }
        }

        h5 {
            margin-top: 5px;
            color: #b13f3f;
            font-size: 15px;
            font-weight: bold;

            @media screen and (max-width: 426px) {
                margin-top: -1px;
            }
        }
    }

    .thumbs-down-img {
        margin-left: 15px;
        float: left;

        @media screen and (max-width: 426px) {
            margin-left: 7px;
        }
    }
}

.thankYou {
    font-size: 1.75rem;
    line-height: 1.5;
}

.pFeedback {
    display: block;
}

.alice-feedback {
    float: left;
    margin-bottom: 0px;
}

.home-page {
    .alice-answer {
        display: none;
    }
}

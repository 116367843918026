﻿
/* Autocomplete */
.search-bar {
    flex: 0 1 auto;
    width: 100vw;
    height: 2%;
}

.autocomplete-order-row {
    width: 100%;

    button {
        width: 100%;
        padding: 20px;


        @media screen and (min-width: $tablet) {
            margin: 10px;
            width: auto;
        }
    }

    &.fixed-header {
        position: fixed;
        top: 73px;
        left: 0px;
        width: 100%;
        background-color: $white;
        margin: 0 auto;
        display: block;
        z-index: 10;

        @media screen and (min-width: $tablet) {
            position: fixed;
            top: 10px;
            left: 250px;
            max-width: 450px;
            width: 100%;
        }

        @media screen and (min-width: $desktop) {
            max-width: 400px;
            left: 250px;
        }

        @media screen and (min-width: $larger) {
            max-width: 500px;
            @include center(x);
        }
    }
}

.autocomplete-dropdown-container {
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border: 1px solid $white;
    position: absolute;
    background-color: $white;
    z-index: 1000;
    text-align: left;
    width: calc(100% + 10px);
    left: -5px;
    z-index: 1;
    top: -5px;
    padding: 16px;
    padding-top: 100px;

    .message-holder {
        padding: 10px;
    }

    ul.suggestions {
        padding: 0px;
        max-height: 300px;
        overflow-y: auto;
        height: auto;

        &:empty {
            height: 0px;
        }

        li {
            padding: 16px;
            border: 0px solid $white;
            min-height: 55px;
            height: auto;
            font-size: 20px;
            line-height: 1.5;

            small {
                display: block;
                font-weight: bold;
                font-size: 12px;
                line-height: 1.5;
                color: #666;
            }

            &:after {
                content: "";
                width: 0px;
                height: 0px;
            }
        }
    }
}

.suggestion-active,
.suggestions li:hover,
.suggetsion li:focus {
    background-color: #efefef;
    border: 0px solid $green;
    cursor: pointer;
    font-weight: 700;
}

.autocomplete-input-and-dropdown {
    margin-left: 25%;
    top: -32px;
    width: 50%;
    position: relative;
    z-index: 1;


    @media screen and (max-width: $tablet) {
        float: left;
        margin-left: 5%;
        width: 90%;
    }

    .input-wrapper {
        position: relative;
        z-index: 2;

        input {
            margin: 0;
            padding-right: 64px;
            width: 100%;
            border-radius: 5px;
            float: left;
            border: 2px solid #D6CFDA;
            height: 64px;
            box-shadow: 0px 8px 24px #b776ca52;
            -moz-box-shadow: 0px 8px 24px #b776ca52;
            -webkit-box-shadow: 0px 8px 24px #b776ca52;
            -khtml-box-shadow: 0px 8px 24px #b776ca52;
            text-overflow: ellipsis;

            ::placeholder {
                text-overflow: ellipsis;
            }

            &:focus {
                border: 2px solid $purple;
                outline: none;
            }
        }

        #searchSuggestions {
            border-radius: 35px;
            border: 5px solid $purple;
        }

        .clear-button,
        .clear-button:active,
        .clear-button:focus {
            position: absolute;
            top: 0px;
            font-weight: 600;
            color: #3d1951;
            cursor: pointer;
            padding: 20px;
            float: right;
            right: 0;
        }
    }

    .autocomplete-dropdown-container {
        border-radius: 36px 36px 16px 16px;
    }
}

.search-buttons {
    position: absolute;
    right: 24px;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
}

.button.search-button {
    background-color: transparent;
    border: none;
    color: #808080;
    padding: 10px 0;
    margin: 0;
    float: left;
    font-size: 26px;
    line-height: 1.8;
    transition: none;

    &:hover, &:active, &:focus {
        background-color: transparent;
        font-size: 26px;
        line-height: 1.8;
        padding: 10px 0;
        margin: 0;
        color: #808080;
    }
}
// Hide the default clear button on IE and Edge
input[type=text]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

.no-suggestions {
    color: $white;
}

﻿header {
    background-color: $white;
    border-bottom: 0px solid $purple;
    z-index: 10;
    @media screen and (max-width: $mobile) {
        height: 150px;
    }
}

.header-uhm.alice-header {
    padding: 0px;
    width: 100%;
    background-color: $white;
    border-bottom: 0px solid $purple;
    z-index: 10;
}

.mobile-menu {
    margin: 0px 10px;
}
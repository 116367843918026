﻿/* Drawer Component */
.background-drawer {
    background-color: $trans-purple;
    position: fixed;
    right: -5000px;
    top: 0;
    justify-content: flex-end;
    z-index: 999;
    transition: all ease 200ms;
    overflow-y: inherit;
    display: flex;
    align-items: center;

    &.show-drawer {
        background-color: rgba(61, 25, 81, 0.8);
        width: 100vw;
        height: 100vh;
        transition: all ease 200ms;
        right: 0;
    }

    &.hide-drawer {
        width: 0;
        height: 100vh;
        transition: all ease 200ms;

        .close-drawer {
            right: -5000px;
        }
    }

    .popup {
        width: 400px;
        height: min-content;
        border-radius: 8px;
        border: none;
        margin: 0 auto;
        background-color: $white;
        padding: 16px;

        .close-drawer {
            position: absolute;
            top: 24px;
            right: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .drawer-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            border-bottom: 1px solid $border-gray;
            margin-bottom: 40px;
        }

        .drawer-content {
            text-align: center;

            .drawer-split {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                gap: 16px;
                margin-top: 24px;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .popup {
            width: 90vw;
            max-width: 400px;
        }
    }

    .popup-small {
        width: 400px;
        height: min-content;
        border-radius: 8px;
        border: none;
        position: relative;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, .75);
        font-weight: bold;
        text-align: center;
        padding: 8px;

        .close-drawer {
            position: absolute;
            top: 5%;
            right: 2%;
            cursor: pointer;
        }

        .drawer-container-small {
            height: 100%;
        }

        .drawer-content-small {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            text-align: center;
        }

        .drawer-content {
            height: 151px;

            h2 {
                padding-top: 24px;
                padding-bottom: 12px;
            }

            .drawer-split {
                .cancel {
                    height: 40px;
                    width: 174px;
                    margin-right: 19px;
                }

                .save {
                    height: 40px;
                    width: 174px;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .popup-small {
            width: 90vw;
            max-width: 400px;
        }
    }

    .drawer {
        right: 0;
        transition: all ease 200ms;

        .close-drawer {
            position: absolute;
            top: 28px;
            right: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .drawer-container {
            width: 50vw;
            height: 100vh;
            display: block;
            padding: 24px 24px 16px 16px;
            background-color: white;
            -webkit-box-shadow: -5px 3px 7px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: -5px 3px 7px rgba(0, 0, 0, 0.3);
            box-shadow: -5px 3px 7px rgba(0, 0, 0, 0.3);

            .drawer-header {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                gap: 16px;
                border-bottom: 1px solid $border-gray;
                margin-bottom: 40px;
                align-items: center;

                svg {
                    height: 24px;
                    width: 24px;
                }
            }

            .drawer-content {

                .drawer-split {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    gap: 16px;

                    .input-container {
                        width: 95%;
                    }

                    .split-container {
                        width: 100%;

                        select {
                            width: 100%;
                            margin: 0px;
                        }

                        .dropdown svg {
                            position: relative;
                            bottom: 32px;
                            left: 94%;
                        }
                    }
                }
            }

            .input-container {
                margin-top: 16px;
            }
        }
    }

    .file-upload {
        border: 1px dashed $border-gray;
        color: $border-gray;
        border-radius: 5px;
        padding: 10px;
        font-size: 50px;

        label {
            color: $green;
            font-size: 18px;
            text-decoration: underline;
            font-weight: normal;
        }

        label:hover {
            cursor: pointer;
        }

        .upload-text {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .file-upload-dragging {
        background-color: $light-gray;
        border: 1px dashed $border-gray;
        color: $border-gray;
        border-radius: 5px;
        padding: 10px;
        font-size: 50px;

        label {
            color: $green;
            font-size: 18px;
            text-decoration: underline;
            font-weight: normal;
        }

        .upload-text {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .file-loading {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        p {
            font-weight: normal;
            font-size: 12px;
        }

        .cancel-upload {
            cursor: pointer;
        }
    }
    //Drawer buttons
    .save {
        border-radius: 25px;
        width: 100%;
        background-color: $green !important;
        border: 1px solid $green !important;
        color: $white !important;
        padding: 10px 0px;
        font-size: 12px !important;
        letter-spacing: 1px;

        &:focus, &:active, &:hover {
            padding: 10px 0px !important;
            border-radius: 25px;
        }

        svg {
            margin-right: 10px;
        }
    }

    .save:hover {
        background-color: $purple !important;
        border: 1px solid $purple !important;
    }

    .cancel {
        border-radius: 25px;
        border: 1px solid $black;
        width: 100%;
        background-color: $white;
        color: $black;
        padding: 10px 0px;
        font-size: 12px !important;
        letter-spacing: 1px;

        &:focus, &:active, &:hover {
            padding: 10px 0px !important;
            border-radius: 25px;
        }

        svg {
            margin-right: 10px;
        }
    }

    .cancel:hover {
        color: $white;
        background-color: $purple;
        border: 1px solid $purple;
    }

    .delete {
        border-radius: 25px;
        width: 100%;
        background-color: $error;
        border: 1px solid $error;
        color: $white;
        padding: 10px 0px;
        font-size: 12px !important;
        letter-spacing: 1px;

        &:focus, &:active, &:hover {
            padding: 10px 0px !important;
            border-radius: 25px;
        }

        svg {
            margin-right: 10px;
        }
    }

    .delete:hover {
        background-color: $purple;
        border: 1px solid $purple;
    }

    .update {
        border-radius: 25px;
        border: 1px solid $black;
        width: 100%;
        background-color: $white;
        color: $black;
        padding: 10px 0px;
        font-size: 12px !important;
        letter-spacing: 1px;

        &:focus, &:active, &:hover {
            padding: 10px 0px !important;
            border-radius: 25px;
        }

        svg {
            margin-right: 10px;
        }
    }

    .update:hover {
        color: $white;
        background-color: $green;
        border: 1px solid $green;
    }
    
    .validation-error {
        color: $error;
        font-size: 12px;
    }
}
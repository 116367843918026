﻿/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.bounce-loader {
    margin: 0 auto;
    width: 70px;
    text-align: center;
}

.bounce-loader > .dot {
    width: 18px;
    padding: 2px;
    height: 18px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.bounce-loader > .dot:nth-child(1) {
    animation-delay: -0.32s;
    background-color: #68477c
}

.bounce-loader > .dot:nth-child(2) {
    animation-delay: -0.16s;
    background-color: $green;
}

.bounce-loader > .dot:nth-child(3) {
    animation-delay: 0s;
    background-color: $purple;
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

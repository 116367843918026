﻿nav.open {
    z-index: 2;
}

.alice-header {

    .alice-icons {
        overflow-y: hidden;

        @media screen and (max-width: 769px) {
            -moz-box-shadow: 0px 0px 16px #00000029;
            -webkit-box-shadow: 0px 0px 16px #00000029;
            box-shadow: 0px 0px 16px #00000029;
        }

        .left-list {
            float: left;

            @media screen and (max-width: 769px) {
                right: 50%;
                width: 50%;
            }

            #help-nav-left {
                display: unset;
            }

            #ask-alice-logo-list {
                display: none;

                #ask-alice-logo-nav {
                    display: none;
                }
            }
        }

        .right-list {
            margin-right: 10px;

            @media screen and (max-width: 769px) {
                width: 50%;
                float: left;
                margin-left: 0;
                margin-right: auto;
            }

            #help-nav-right {
                visibility: hidden;
                height: 0px;
                width: 0px;
            }
        }
    }
}

nav.nav-uhm.alice-icons {
    position: relative;
    width: 100%;
    margin-bottom: 0px;

    @media screen and (max-width: 769px) {
        z-index: 999;
        visibility: visible;
        opacity: 1;
        height: 80px;
        position: fixed;
        margin: 0;
        padding: 0;
        background-color: #F6F3F7;
        top: unset;
        bottom: 0;
    }
}

nav ul {
    padding-top: 110px;
    background-color: transparent;

    @media screen and (max-width: 769px) {
        padding: 0;
        width: 100%;
    }

    @media screen and (min-width: $desktop) {
        padding-top: 0px;
    }

    li {
        line-height: 30px;
        text-align: center;
        margin: 25px auto;
        text-align: left;

        @media screen and (max-width: 769px) {
            height: 34px;
            width: 50%;
            margin: auto;
        }

        @media screen and (min-width: $desktop) {
            height: auto;
            margin: 0px;
        }

        a,
        a:link,
        a:active,
        a:visited {
            text-align: center;
            display: block;
            height: auto;
            background-color: transparent;
            border-radius: 5px;
            font-weight: 700;

            @media screen and (max-width: 769px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 13px;
            }

            .icon-button-text {
                text-align: left;
                float: left;
                margin-left: 10px;
                font-size: 12px;
                padding: 10px 0px;
                line-height: 30px;
                color: #fff;

                @media screen and (max-width: 769px) {
                    color: #68646C;
                    margin: 0px;
                    padding: 0px;
                }

                @media screen and (min-width: $desktop) {
                    float: none;
                    text-align: center;
                    font-size: 12px;
                    line-height: 20px;
                    margin-left: 0px;
                }
            }
        }

        a:hover {
            background-color: $purple;
            color: $white;

            svg {
                color: white;
            }

            .icon-button-text {
                color: white;
            }
        }
    }
}

.icon-button {
    width: 16px;
    float: left;
    color: #fff;

    @media screen and (max-width: 769px) {
        color: #68646C;
    }

    @media screen and (min-width: $desktop) {
        float: none;
        position: relative;
        top: 0px;
        margin: 0 auto;
        display: block;
    }
}

.scrolling-navbar {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100vw;
    height: 64px;
    background: #F6F3F7;
    transition: background ease 450ms;
    -moz-box-shadow: 0px 0px 16px #00000029;
    -webkit-box-shadow: 0px 0px 16px #00000029;
    box-shadow: 0px 0px 16px #00000029;

    @media only screen and (max-width: 769px) {
        background: transparent;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .alice-icons {
        margin-top: 6px;

        svg {
            color: #68646C;
        }

        .icon-button-text {
            color: #68646C;
        }

        li:hover {
            svg {
                color: #FFF;
            }

            .icon-button-text {
                color: #FFF;
            }
        }

        .left-list {
            #home-nav {
                display: unset;
            }

            #ask-alice-logo-list {
                display: none;
            }
        }

        .right-list {
            #help-nav-right {
                display: none;

                @media only screen and (max-width: 769px) {
                    display: unset;
                }
            }
        }
    }

    #ask-alice-logo-purple {
        height: 32px;
        width: 111px;
        position: fixed;
        left: calc(50% - 55.5px);
        top: 16px;

        @media only screen and (max-width: 769px) {
            display: none;
        }
    }
}

.alice-header.scrolling-navbar {
    #help-nav-left {
        display: unset;

        @media only screen and (max-width: 769px) {
            display: unset;
        }
    }
}

.alice-header-answer-page.scrolling-navbar {
    .left-list {
        #help-nav-left {
            display: unset;

            @media only screen and (max-width: 769px) {
                display: none;
            }
        }
    }
}

.static-navbar {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100vw;
    height: 64px;
    background-color: transparent;
    transition: background-color ease 450ms;

    @media only screen and (max-width: 769px) {
        z-index: 0;
    }

    .alice-icons {
        margin-top: 6px;

        .left-list {
            margin-top: 0px;
        }

        .right-list {
            margin-top: 0px;
        }
    }
}
